input {
  height: 24px;
  border-radius: 8px;
  border-width: 1px;
  border-color: #D0D5DD;
  border-style: solid;
  padding: 8px;
}

button {
  font-size: 14px;
}

p {
  font-size: 14px;
}

.title {
  margin-bottom: 8px;
}

.subtitle {
  font-size: 16px;
  text-align: center;
  color: #475467;
  margin-top: 8px;
}

.label {
  color: #344054;
  margin-bottom: 8px;
}